import * as React from 'react'
import Layout from '/src/components/layout'

const FragorSvarPage = () => {
    return (
        <Layout pageTitle="Frågor &amp; svar om Laddify">
            
            <a id="vilka-natverk"></a>
            <h2>Vilka nätverk finns i Laddify?</h2>
            <p>Lista som uppdateras löpande finns <a href="/om/natverk">här</a>.</p>

            <a id="XXX"></a>
            <h2>Är Laddify gratis?</h2>
            <p>Ja, både webbsida laddify.se och (kommande) appar för iOS och Android är gratis att använda. Drift och utveckling av sidan finaniseras genom reklam/annonser.</p>
            
            <a id="XXX"></a>
            <h2>Kan jag påverka Laddify?</h2>
            <p>Ja, se <a href="/om/forslag">här</a>.</p>
            
            <a id="XXX"></a>
            <h2>Behövs verkligen Laddify? Det finns ju redan liknande tjänster!</h2>
            <p>Syftet med Laddify är inte att bara skapa en ny tjänst likt andra utan att skapa en bättre tjänst. Målet är att elbilsförare enkelt och snabbt ska hitta för dem rätt laddstation. Exempelvis genom en rad möjligheter till filtrering. Läs mer om Laddify <a href="/om">här</a>.</p>
            
            <a id="XXX"></a>
            <h2>Vet nätverken om att de visas i Laddify?</h2>
            <p>Information hämtas endast från tillåtna källor och lika så visas endast loggor efter samtycke.</p>
            
            <a id="XXX"></a>
            <h2>Vem står bakom Laddify?</h2>
            <p>Erik heter Laddifys skapare och mer om honom går att läsa på <a href="https://iterik.se" target="_blank" rel="noreferrer">iterik.se</a>. Laddify drivs av Aktiebolag Erik Harlefelt.</p>
            
            <a id="XXX"></a>
            <h2>Använder webbsidan kakor?</h2>
            <p>Ja, läs mer om kakor och liknande tekniker <a href="/om/kakor">här</a>.</p>

            <a id="varfor-jmfpris"></a>
            <h2>Varför jämförelsepris?</h2>
            <p>För att det ska vara enkelt att jämföra och filtera stationer räknas ett jämförelsepris ut för varje laddpunkt. Jämförelsepriset är i kronor SEK per kWh. Nätverkets priser gäller alltid och visas på stationssidan i möjligaste mån.</p>

            <a id="hur-raknas-jmfpris"></a>
            <h2>Hur räknas jämförelsepris ut?</h2>
            <p>Om priset redan är i <b>SEK/kWh</b> görs ingen omräkning.</p>
            <p>Om priset är i <b>annan valuta/kWh</b> görs en en gång per dygn omräkning enligt gällande valutakurs från europeiska centralbanken.</p>
            <p>Om priset är <b>per minut/timme</b> görs vid snabbladdning en uträkning med 80% av den möjliga maxeffekten på en timme. Exempelvis för en laddpunkt på 50 kW med minutpriset 3 kr blir jämförelsepriset 4,5 kr/kWh. Det verkliga priset kan vara lägre eller högre än jämförelsepriset. För destinationsladdning används uttagets effekt upp till 11 kW.</p>
            <p>Om det finns en <b>startavgift</b> sker uträkning enligt ovan samat att startavgift adderas.</p>

            <a id="visas-alla-stationer"></a>
            <h2>Visas alla stationer?</h2>
            <p>Nej, endast publika laddstationer visas i Laddify.</p>

        </Layout>
    )
}

export default FragorSvarPage